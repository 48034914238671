import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import TermsModal from "../TermsModal";
import MapModal from "../MapModal";
import ThanksModal from "../../ThanksModal";
import ThanksCouponModal from "../../ThanksCouponModal";
import ValidSMSModal from "../../ValidSMSModal";
import APILoader from "../../APILoader";
import OrderTimeModal from "../OrderTimeModal";
import Geocode from 'react-geocode';
import CardNumberModal from "../CardNumberModal";
import ReactPixel from 'react-facebook-pixel';

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class PaymentOptionForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            errors: [],
            termsModalIsOpen: false,
            mapModalIsOpen: false,
            thanksModalIsOpen: false,
            smsModalIsOpen: false,
            apiLoaderIsOpen: false,
            orderTimeModalIsOpen: false,
            isCheckedTerms: this.props.myShopApiStore.userToken ? true : false,
            cardNumberModalIsOpen: false
        }
    }
    
    optionBtnsStyle = (isCreditBtn = false) => {
        const { orderData, mainColor } = this.props.myShopApiStore;
        if(isCreditBtn){
            if(orderData.isCredit){
                return{
                    backgroundColor: mainColor,
                    color: "#FFFF" 
                }
            }
            else{
                return{
                    backgroundColor: "#eeeeee",
                    color: "#9b9a9a" 
                }
            }
        } else {
            if(!orderData.isCredit){
                return{
                    backgroundColor: mainColor,
                    color: "#FFFF" 
                }
            }
            else{
                return{
                    backgroundColor: "#eeeeee",
                    color: "#9b9a9a" 
                }
            }
        }
    }
    
    handleChange = (event) => {
        const { name, value, checked } = event.target;

        if(name === 'isCheckedTerms'){
            this.setState({[name]: checked})
        }
        else{
            this.props.myShopApiStore.setUserParams(name, value)
        }

        let errors = this.state.errors;
        const eIndex = errors.indexOf(name);
        if (eIndex > -1) {
            errors.splice(eIndex, 1);
            this.setState({
                errors: errors,
            });
        }
    }

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    errorConditions = () => {
        const { userParams } = this.props.myShopApiStore;
        let errors = [];
        if(userParams.name.trim() === ''){
            errors.push('name')
        }
        if(userParams.phone.trim() === '' || !/^\d+$/.test(userParams.phone) || userParams.phone.length !== 10){
            errors.push('phone')
        }
        if(userParams.email.trim() === '' || !this.validateEmail(userParams.email.trim())){
            errors.push('email')
        }
        if(userParams.city.trim() === ''){
            errors.push('city')
        }
        if(userParams.streetName.trim() === ''){
            errors.push('streetName')
        }
        if(userParams.streetNumber.trim() === ''){
            errors.push('streetNumber')
        }
        if(!this.state.isCheckedTerms){
            errors.push('isCheckedTerms')
        }

        return errors;
    }

    setLatLngFromAddrsResp = async () => {
        const { streetName, streetNumber, city } = this.props.myShopApiStore.userParams;
        const { setDeliveryLatLng, setUserParams } = this.props.myShopApiStore;
        await Geocode.fromAddress(`${city} ${streetName} ${streetNumber}`).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                this.props.myShopApiStore.setDeliveryLatLng({lat, lng});
            },
            (error) => {
                console.error(error);
                setDeliveryLatLng({lat: 32.085300, lng: 34.781769});
                setUserParams('streetName', '');
            }
          );
    }

    handleSubmit = async (event) => {
        // event.preventDefault();
        const { shop } = this.props.myShopApiStore;
        shop.isBusiness && this.handleDeliveryGuyClick()

        const { orderData, registerUser } = this.props.myShopApiStore;

        if(this.state.apiLoaderIsOpen){ //cancle multiple (in a row) clickes on send button!
            return null;
        }

        const errors = this.errorConditions();

        if(!errors.length){
            
            this.openAPILoader()
            await this.setLatLngFromAddrsResp(); //set lat lng parameters
            const regResp = await registerUser();
            this.closeAPILoader();

            regResp.data ? this.openMapModal() : this.openSmsModal();
            
            if(orderData.isCredit){  //payment with credit!
                // //set lat lng parameters
                // await this.setLatLngFromAddrsResp();

                // if(!userToken){
                //     this.openAPILoader()
                //     await registerUser();
                //     this.closeAPILoader();
                //     this.openSmsModal();
                // }

                // else{//openning map!  (**next-step for new user -->> in closeSmsModal function!)
                //     this.openMapModal();
                // }
                
                ///->>>>
                // this.openAPILoader()
                // const regResp = await registerUser();
                // this.closeAPILoader();

                // regResp.data ? this.openMapModal() : this.openSmsModal();
                ///->>>>
                
            }
            else{ //payment to delivery guy!

                // //set lat lng parameters
                // await this.setLatLngFromAddrsResp();
                
                // if(!userToken){
                //     this.openAPILoader()
                //     await registerUser();
                //     this.closeAPILoader();
                //     this.openSmsModal();
                // }
                // else{ //openning map!  (**making order for new user -->> in closeSmsModal function!)
                //     this.openMapModal();
                // }

                // ///->>>>
                // this.openAPILoader()
                // const regResp = await registerUser();
                // this.closeAPILoader();

                // regResp.data ? this.openMapModal() : this.openSmsModal();
                ///->>>>
            }
        }
        else{
            this.setState({
                errors
            })
        }
    }

    openAPILoader = () => {
        this.setState({apiLoaderIsOpen: true})
    }

    closeAPILoader = () => {
        this.setState({apiLoaderIsOpen: false})
    }

    closeOrderTimeModal = () => {
        this.setState({orderTimeModalIsOpen: false})
    }

    openOrderTimeModal = () => {
        this.setState({orderTimeModalIsOpen: true})
    }

    openSmsModal = () => {
        this.setState({smsModalIsOpen: true})
    }
    closeSmsModal = async () => {
        this.setState({smsModalIsOpen: false})
        
        // const { userToken } = this.props.myShopApiStore;
        // if(userToken){ //open map modal for new user
        //     this.openMapModal();
        // }
    }
    
    openThanksModal = () => {
        this.setState({thanksModalIsOpen: true})
    }

    closeThanksModal = () => {
        this.setState({thanksModalIsOpen: false})
        this.props.ifaceMngr.setActiveTopMenu("ShopMenu");
    }

    openCardNumberModal = () => {
        this.setState({cardNumberModalIsOpen: true})
    }

    closeCardNumberModal = () => {
        this.setState({cardNumberModalIsOpen: false})
    }
    
    handleCreditClick = () => {
        const { setOrderData } = this.props.myShopApiStore;

        if(process.env.REACT_APP_FACEBOOK_PIXEL != '') ReactPixel.track('AddPaymentInfo'); 

        setOrderData("isCredit", true);
    }

    handleDeliveryGuyClick = () => {
        const { setOrderData } = this.props.myShopApiStore;
        setOrderData("isCredit", false);
    }
    
    openTermsModal = () => {
        this.setState({termsModalIsOpen: true})
    }
    
    closeTermsModal = () => {
        this.setState({termsModalIsOpen: false})
    }

    openMapModal = () => {
        this.setState({mapModalIsOpen: true})
    }

    closeMapModal = () => {
        this.setState({mapModalIsOpen: false})
    }

    render() {
        if(this.props.currentStep !== 2){
            return null;
        } 
        const { userParams, orderData, mainColor,
                shop, setOrderData, hasPostCoupon,
                setOtherPaymentType } = this.props.myShopApiStore;

        const { paymentTypes } = shop;
        const canPayByCredit = paymentTypes?.some(pt => pt === "credit");
        const canPayByCash = paymentTypes?.some(pt => pt === "cash");
        const canPayByCibus = paymentTypes?.some(pt => pt === "cibus");
        const canPayByTenbis = paymentTypes?.some(pt => pt === "tenbis");
        const canPayByGoodi = paymentTypes?.some(pt => pt === "goodi");
        const canPayByDelay = paymentTypes?.some(pt => pt === "delay");

        // setOrderData("isCredit", false); //ADDED !!!!!
        return (
            <div className={style.Container}>
                <div className={style.UserChoosing}>
                    {
                        shop.isBusiness
                        ?
                            ""
                        :
                            <div className={style.OptionBtns}>
                                {/* {canPayByCredit ? <button onClick={this.handleCreditClick} style={this.optionBtnsStyle(true)}>תשלום באשראי</button> : null}
                                {canPayByCash ? <button onClick={this.handleDeliveryGuyClick} style={this.optionBtnsStyle()}>תשלום לשליח</button> : null} */}
                            </div>
                    }
                    <div className={style.InputFields}>
                        <form onSubmit={this.handleSubmit}>
                            <div className={style.Inputs}>
                            {
                                orderData.isCredit
                                ? 
                                    <>
                                        <input type="text" name="name" value={userParams.name} onChange={this.handleChange} placeholder="*שם מלא" style={{border: this.state.errors.includes('name') ? '1px solid #FF0000' : 'none'}}/>
                                        <input type="tel" name="phone" value={userParams.phone} onChange={this.handleChange} placeholder="*טלפון נייד" style={{border: this.state.errors.includes('phone') ? '1px solid #FF0000' : 'none'}}/>
                                        <input type="email" name="email" value={userParams.email} onChange={this.handleChange} placeholder="מייל" style={{border: this.state.errors.includes('email') ? '1px solid #FF0000' : 'none'}}/>
                                        <input type="text" name="city" value={userParams.city} onChange={this.handleChange} placeholder="*עיר" style={{border: this.state.errors.includes('city') ? '1px solid #FF0000' : 'none'}}/>
                                        <div className={style.StreetField}>
                                            <input type="text" name="streetName" value={userParams.streetName} onChange={this.handleChange} placeholder="*רחוב" style={{border: this.state.errors.includes('streetName') ? '1px solid #FF0000' : 'none', /*color: 'transparent', textShadow: '0 0 0 #000000'*/}} />
                                        </div>
                                        <div className={style.TowInputInRow}>
                                            <input type="text" name="streetNumber" value={userParams.streetNumber} onChange={this.handleChange} placeholder="*מספר בית" style={{border: this.state.errors.includes('streetNumber') ? '1px solid #FF0000' : 'none'}}/>
                                            <input type="text" name="entranceCode" value={userParams.entranceCode} onChange={this.handleChange} placeholder="קוד כניסה"/>
                                        </div>
                                        <div className={style.TowInputInRow}>
                                            <input type="number" name="apartmentNumber" value={userParams.apartmentNumber} onChange={this.handleChange} placeholder="מספר דירה"/>
                                            <input type="number" name="floorNumber" value={userParams.floorNumber} onChange={this.handleChange} placeholder="קומה"/>
                                        </div>
                                        <div className={style.CheckTerm}>
                                            <input type="checkbox" name='isCheckedTerms' checked={this.state.isCheckedTerms} onChange={this.handleChange}/>
                                            <span style={{color: this.state.errors.includes('isCheckedTerms') ? '#FF0000' : '#FFF'}}>
                                            אני מאשר/ת שקראתי את
                                            {" "}
                                            <span className={style.TextTermBtn} onClick={this.openTermsModal} style={{color: this.state.errors.includes('isCheckedTerms') ? '#FF0000' : 'rgb(255,255,255,0.75)'}}>התקנון</span>
                                            {" "}
                                            ואת מדיניות הפרטיות ואני מסכים/ה לתנאים
                                            </span>
                                        </div>
                                    </>
                                : 
                                    <>
                                        <input type="text" name="name" value={userParams.name} onChange={this.handleChange} placeholder="*שם מלא" style={{border: this.state.errors.includes('name') ? '1px solid #FF0000' : 'none'}}/>
                                        <input type="tel" name="phone" value={userParams.phone} onChange={this.handleChange} placeholder="*טלפון נייד" style={{border: this.state.errors.includes('phone') ? '1px solid #FF0000' : 'none'}}/>
                                        <input type="email" name="email" value={userParams.email} onChange={this.handleChange} placeholder="מייל" style={{border: this.state.errors.includes('email') ? '1px solid #FF0000' : 'none'}}/>
                                        <input type="text" name="city" value={userParams.city} onChange={this.handleChange} placeholder="*עיר" style={{border: this.state.errors.includes('city') ? '1px solid #FF0000' : 'none'}}/>
                                        <div className={style.StreetField}>
                                            {/* <div className={style.LeftElement}> */}
                                                {/* <label onClick={this.openMapModal}>לא מוצא את הכתובת?</label> */}
                                                {/* <label onClick={this.openMapModal}>לחץ כאן</label> */}
                                            {/* </div> */}
                                            <input type="text" name="streetName" value={userParams.streetName} onChange={this.handleChange} placeholder="*רחוב" style={{border: this.state.errors.includes('streetName') ? '1px solid #FF0000' : 'none'}} />
                                        </div>
                                        
                                        <div className={style.TowInputInRow}>
                                            <input type="text" name="streetNumber" value={userParams.streetNumber} onChange={this.handleChange} placeholder="*מספר בית" style={{border: this.state.errors.includes('streetNumber') ? '1px solid #FF0000' : 'none'}}/>
                                            <input type="text" name="entranceCode" value={userParams.entranceCode} onChange={this.handleChange} placeholder="קוד כניסה" />
                                        </div>
                                        <div className={style.TowInputInRow}>
                                            <input type="number" name="apartmentNumber" value={userParams.apartmentNumber} onChange={this.handleChange} placeholder="מספר דירה"/>
                                            <input type="number" name="floorNumber" value={userParams.floorNumber} onChange={this.handleChange} placeholder="קומה"/>
                                        </div>
                                        <div className={style.CheckTerm}>
                                            <input type="checkbox" name='isCheckedTerms' checked={this.state.isCheckedTerms} onChange={this.handleChange}/>
                                            <span style={{color: this.state.errors.includes('isCheckedTerms') ? '#FF0000' : 'rgb(255,255,255)'}}>
                                            אני מאשר/ת שקראתי את
                                            {" "}
                                            <span className={style.TextTermBtn} onClick={this.openTermsModal} style={{color: this.state.errors.includes('isCheckedTerms') ? '#FF0000' : '#FFF'}}>התקנון</span>
                                            {" "}
                                            ואת מדיניות הפרטיות ואני מסכים/ה לתנאים
                                            </span>
                                        </div>
                                    </>
                            }
                            </div>
                            <div className={style.BottomBtns}>
                                {/* <button type="submit" style={{backgroundColor: mainColor}}>שלח</button> */}
                                {canPayByCredit ? <button type="button" style={{backgroundColor: mainColor}} onClick={() => {this.handleCreditClick(); this.handleSubmit()}}>תשלום באשראי</button> : null}
                                {canPayByCash ? <button type="button" style={{backgroundColor: mainColor}} onClick={() => {this.handleDeliveryGuyClick(); this.handleSubmit()}}>תשלום לשליח</button> : null}
                                {canPayByCibus ? <button type="button" style={{backgroundColor: mainColor}} onClick={() => {this.handleDeliveryGuyClick(); setOtherPaymentType('cibus'); this.openCardNumberModal()}}>תשלום בסיבוס</button> : null}
                                {canPayByTenbis ? <button type="button" style={{backgroundColor: mainColor}} onClick={() => {this.handleDeliveryGuyClick(); setOtherPaymentType('tenbis'); this.openCardNumberModal()}}>תשלום בתן ביס</button> : null}
                                {canPayByGoodi ? <button type="button" style={{backgroundColor: mainColor}} onClick={() => {this.handleDeliveryGuyClick(); setOtherPaymentType('goodi'); this.openCardNumberModal()}}>תשלום בגודי</button> : null}
                                {canPayByDelay ? <button type="button" style={{backgroundColor: mainColor}} onClick={() => {this.handleDeliveryGuyClick(); setOtherPaymentType('delay'); this.handleSubmit()}}>תשלום בהקפה</button> : null}
                                <button type="button" 
                                    style={{backgroundColor: 'unset', border: '2px solid rgb(255, 255, 255, 0.3)'}}
                                    onClick={this.props.prevStep}>חזור</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={style.Modals}>
                    <CardNumberModal closeModal={this.closeCardNumberModal}
                                     modalIsOpen={this.state.cardNumberModalIsOpen}
                                     handleSendClick={this.handleSubmit}
                                     />
                    <TermsModal closeModal={this.closeTermsModal} modalIsOpen={this.state.termsModalIsOpen} />
                    {
                        hasPostCoupon
                        ?
                            <ThanksCouponModal closeModal={this.closeThanksModal} modalIsOpen={this.state.thanksModalIsOpen} />
                        :
                            <ThanksModal closeModal={this.closeThanksModal} modalIsOpen={this.state.thanksModalIsOpen} />
                    }
                    <ValidSMSModal closeModal={this.closeSmsModal} modalIsOpen={this.state.smsModalIsOpen}
                                   openMapModal={this.openMapModal} action="Delivery" />
                    <APILoader modalIsOpen={this.state.apiLoaderIsOpen} />
                    <OrderTimeModal closeModal={this.closeOrderTimeModal} modalIsOpen={this.state.orderTimeModalIsOpen} orderType={orderData.isDelivery ? 'משלוחים' : 'באיסוף עצמי'}/>
                    <MapModal closeModal={this.closeMapModal} openSmsModal={this.openSmsModal}
                              modalIsOpen={this.state.mapModalIsOpen} nextStep={this.props.nextStep}
                              openThanksModal={this.openThanksModal} closeThanksModal={this.closeThanksModal}
                              thanksModalIsOpen={this.state.thanksModalIsOpen}
                              />

                </div>
            </div>
        )
    }
}

export default PaymentOptionForm
